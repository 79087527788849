<template>
  <v-container class="px-5 mb-10">
    <div v-if="loadAgent">
      <div class="text-center" style="height: 60vh; margin-top: 30vh;">
        <mini-loader class="text-center"></mini-loader>
        <p class="primary--text text-body-1">Preparing dashboard, please wait...</p>
      </div>
    </div>
    
    <div v-if="!loadAgent">
      <p v-if="!dashboard && !loadPage" class="primary--text text-h6 pl-4"><span class="grey--text">Hey!</span> {{ user.name.split(' ')[0] }}</p>
      <v-card v-if="(agent || done) && !loadPage" class="pa-10 py-4 mb-10 rounded grey lighten-4" elevation="0" >
        <v-row>
          <v-col cols="12" md="4" sm="5">
            <div class="secondary--text text-h6">{{ user.name }}</div>
            <div class="primary--text">User: <span class="pl-3 bold-text">{{ user.phone }}</span> </div>
          </v-col>
          <v-col cols="1" md="1" sm="1" class="d-none d-md-block d-sm-block py-0 my-0 pl-0">
            <img alt="" src="@/assets/icons/pos.png" style="opacity: .2" width="100px">
          </v-col>
          <v-row justify="end" class="mx-2 mx-md-0 mx-sm-0" align="center">
              <v-btn text class="white--text grey lighten-1 d-none d-sm-block d-md-block" large @click="requestPOS()">Request POS</v-btn>
              <v-btn text class="white--text grey lighten-1 d-block my-5 mb-10 d-sm-none d-md-none" block large @click="requestPOS()">Request POS</v-btn>
          </v-row>
        </v-row>
        
        <v-row class="my-0 py-0">
          <v-col class="my-0 py-0" cols="12" md="4" sm="4">
            <small class="">Total Balance</small>
            <div class="text-h5 primary--text font-weight-bold">&#8358; {{ agent_details.user.wallet.balance | formatPrice }}</div>
          </v-col>
          <v-col class="my-0 py-0" cols="12" md="4" sm="4">
            <small class="">Pending Balance</small>
            <div class="text-h5 primary--text font-weight-bold">&#8358; {{ agent_details.user.wallet.pending_balance | formatPrice }}</div>
          </v-col>
        </v-row>
      </v-card>
      
      <v-row v-if="!dashboard" justify="center">
        <img
          src="@/assets/icons/pos.png"
          :width="$vuetify.breakpoint.smAndDown ? '80%' : '350'"
          style="opacity: .7"
          v-if="agent"
        >
      </v-row>

      <basic-information v-if="basic" :kyc_details="kyc_details"></basic-information>

      <Dashboard v-if="dashboard && !basic" :agent="agent_details" />

      <update-dialog ref="update"></update-dialog>
    </div>
  </v-container>
</template>
<script>
// import Transfer from '@/components/pos/Transfer'
// import Deposit from '@/components/pos/Deposit'
// import Receive from '@/components/pos/Receive'
import Dashboard from './dashboard'
import MiniLoader from '@/components/MiniLoader'
import BasicInformation from '@/components/pos/BasicInformation'
import UpdateDialog from '@/components/pos/dialogs/updateDialog'
import { mapGetters, mapActions } from 'vuex'
export default {
  layout: 'dashboard',
  transition: 'default',
  components: {
    // Transfer,
    // Deposit,
    Dashboard,
    MiniLoader,
    // Receive,
    UpdateDialog,
    BasicInformation,
  },

  data() {
    return {
      loadAgent: false,
      loadPage: false,
      dashboard: false,
      agent: true,
      basic: false,
      business: false,
      transfer: false,
      deposit: false,
      receive: false,
      paybills: false,
      disabled: true,
      done: false,
      // dialog: false,
      percentage: 0,
      kyc_details: {},
      agent_details: {
        user: {
          wallet: {
            balance: 0,
          }
        }
      },
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),
  },


  mounted() {
    this.getKycLevel()
    this.getKyc()
    this.getAgent()
  },

  methods: {
    ...mapActions('pos', [
      'updatekyc',
      'agentdetails'
    ]),

    ...mapActions('auth', [
      'kycpercentage',
      'getkyc',
    ]),

    basicForm() {
      // this.form = value
      this.agent  = true;
      this.basic  = false;
      this.done   = value;
      window.scrollTo(0, 0)
    },

    closeDialog() {
      this.dialog = false;
    },

    requestPOS() {
      if (this.percentage < 98)
      return this.$refs.update.openDialog()
      this.basic = true
      this.agent = false
    },

    async getKycLevel() {
      try {
        let response    = await this.kycpercentage(this.user.id)
        this.percentage = response.data
      } catch (error) {
        this.$toast.error(error.response.data.message)
      }
    },

    async getKyc() {
      try {
        let response      = await this.getkyc(this.user.id)
        this.kyc_details  = Object.assign({}, response.data)
      } catch (error) {
        this.$toast.error(error.response.data.message)
      }
    },

    async getAgent() {
      this.loadAgent = true
      try {
        let response = await this.agentdetails(this.user.id)
        this.agent_details = Object.assign({}, response.data)
        if (this.agent_details.user.terminals >= 1) {
          this.dashboard = true
        }
        this.loadAgent = false
      } catch (error) {
        this.loadAgent = false
        this.$toast.error(error.response.data.message)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.card-overlay {
  background: linear-gradient(
    292.83deg,
    #171b70 41.23%,
    rgba(193, 161, 61, 0.64) 100%
  );
  opacity: 0.9;
  border-radius: 5px;
}
.v-application .elevation-2 {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.1), 0px 2px 2px 0px rgba(0, 0, 0, 0.07), 0px 1px 5px 0px rgba(0, 0, 0, 0.06) !important;
}
.m-btn {
  cursor: pointer;
}
.cursor {
  cursor: pointer !important;
}
.bold-text {
  font-weight: 500 !important;
  font-size: 14px;
}
</style>
