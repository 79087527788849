<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="600"
      content-class="my-dialog"
    >
      <v-card
        class="pa-4"
      >
        <v-toolbar flat class="d-flex justify-center primary--text">POS Agents Terms and Conditions</v-toolbar>
        <v-divider class="bg-primary"></v-divider>
        <v-card-title class="primary--text">Introduction</v-card-title>
        <v-card-text>
          TRANSAVE TECHNOLOGIES LIMITED is a company registered in Nigeria with an office at 2nd Floor, Suite B12,
          Peace Plaza A, Plot 481, No.35 Ajose Adeogun Street, Utako District Abuja, Nigeria.
          TRANSAVE offer agents and businesses instant and seamless financial services with our point of sale (POS)
          terminals. With our POS services, businesses can grow their enterprises with our hitch free services, manage
          financial emergencies with affordable, collateral-free loans and enjoy unlimited virtual banking opportunities.
        </v-card-text>
        <v-card-title class="primary--text">Terms And Conditions Eligibility</v-card-title>
        <v-card-text>
          To be eligible for the purchase of our POS as an agent, you must open an account with TRANSAVE. Complete your
          KYC (know Your Customer) registration by simply uploading the required documents to get your account verified,
          then you can proceed with your application and thereafter, be assigned our POS terminal, which comes with a
          registered sim card containing 10GB of data.
        </v-card-text>
        <v-card-title class="primary--text">Payments</v-card-title>
        <v-card-text>
          The POS Terminal will cost you a one-time service charge of N15000 (Fifteen Thousand Naira) with an additional
          fee of N1500 which will include our registered SIM card (this is optional). Please note that this fee only
          serves as a service charge for the POS Terminal.
        </v-card-text>
        <v-card-title class="primary--text">Retrieval</v-card-title>
        <v-card-text>
          In the case where you do not meet a target amount of N80,000-N150,000 daily, (on a minimum of 15 transactions)
          or the POS is not in use for 2 days and more, (Sundays excluded), TRANSAVE reserves the right to retrieve the
          POS Terminal.
        </v-card-text>
        <v-card-title class="primary--text">Waybil Cost</v-card-title>
        <v-card-text>
          In the case where our POS will be used outside Abuja, you will be responsible for the waybill charges of
          N1500 – N2000, depending on your location.
        </v-card-text>
        <v-divider class="bg-primary"></v-divider>
        <v-card-text>
          I have read and understood the terms and conditions written above and wish to proceed with my application as
          a POS Agent with TRANSAVE.
        </v-card-text>
        <v-card-actions>
          <v-btn
            elevation="0"
            color="primary"
            @click="acceptTerms()"
          >Accept</v-btn>
          <v-btn
            elevation="0"
            color="grey lighten-3"
            @click="closeDialog()"
          >Decline</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: "termsDialog",
    data() {
      return {
        dialog: false,
      }
    },

    methods: {
      openDialog() {
        this.dialog = true;
      },

      closeDialog() {
        this.dialog = false;
      },

      acceptTerms() {
        this.$parent.terms = true;
        this.closeDialog();
      }
    }
  }
</script>

<style scoped>
  @media(min-width: 600px) {
    div >>> .my-dialog {
      position: absolute;
      right: 0;
    }
  }
</style>