<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="530"
    >
      <v-card
        class="pa-10"
      >
        <v-toolbar color="secondary--text text-h5" flat class="d-flex justify-center">Oooops!</v-toolbar>
        <v-card-title class="primary--text text-h6">Kindly update your KYC to premium Level</v-card-title>
        <v-card-text class="py-0">
          To request a POS, you have to update your KYC with the necessary documents required
        </v-card-text>
        <v-card-actions class="d-flex justify-center mt-7">
          <v-btn
            large
            text
            class="grey lighten-2 px-5 grey--text"
            @click="closeDialog()"
          >Update Later</v-btn>
          <v-btn
            large
            text
            class="primary px-5"
            @click="updateKYC()"
          >Update Now</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false
    }
  },

  methods: {
    openDialog() {
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
    },

    updateKYC() {
      this.$router.push({ name: 'Accounts', query: { pos_request: 'yes'} })
    },
  }
}
</script>