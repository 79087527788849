<template>
  <div class="pa-2 pa-md-0">
    <div v-if="loadPage">
      <div class="text-center" style="height: 60vh; margin-top: 30vh;">
        <mini-loader class="text-center"></mini-loader>
        <p class="primary--text text-body-1">Preparing dashboard, please wait...</p>
      </div>
    </div>
    <div v-if="!loadPage">
      <v-row class="no-gutters white elevation-2 pa-2" v-if="!transactions">
        <v-col sm="4" cols="6" md="">
          <v-card
            flat
            class="py-3"
          >
            <v-card-text class="py-0 my-0 primary--text"><small>Total Balance</small></v-card-text>
            <v-card-title class="primary--text py-0 my-0 font-weight-bold"><small>₦ {{ agent_details.user.wallet.balance | formatPrice }}</small></v-card-title>
          </v-card>
        </v-col>
        <v-col sm="4" cols="6" md="">
          <v-card
            flat
            class="py-3"
          >
            <v-card-text class="py-0 my-0 primary--text"><small>Total Transactions</small></v-card-text>
            <v-card-title class="primary--text py-0 my-0 font-weight-bold"><small>{{ agent_details.user.transactions }}</small></v-card-title>
          </v-card>
        </v-col>
        <v-col sm="4" cols="6" md="">
          <v-card
            flat
            class="py-3"
          >
            <v-card-text class="py-0 my-0 primary--text"><small>Total Transaction Amount</small></v-card-text>
            <v-card-title class="primary--text py-0 my-0 font-weight-bold"><small>₦ {{ agent_details.user.amount | formatPrice}}</small></v-card-title>
          </v-card>
        </v-col>
        <v-col sm="4" cols="6" md="">
          <v-card
            flat
            class="py-3"
          >
            <v-card-text class="py-0 my-0 primary--text"><small>Number of Terminals</small></v-card-text>
            <v-card-title class="primary--text py-0 my-0 font-weight-bold"><small>{{ agent_details.user.terminals }}</small></v-card-title>
          </v-card>
        </v-col>
        <v-col sm="4" cols="6" md="">
          <v-card
            flat
            class="py-3"
          >
            <v-card-text class="py-0 my-0 primary--text"><small>Total Commission</small></v-card-text>
            <v-card-title class="primary--text py-0 my-0 font-weight-bold"><small>₦ 0.00 <v-chip color="primary--text grey lighten-5" class="pa-1" small label><small>0%</small></v-chip></small></v-card-title>
          </v-card>
        </v-col>
        <v-col sm="4" cols="6" md="">
          <v-card
            flat
            class="text-center"
          >
            <v-card-text class="primary--text pb-0"><small>Target</small></v-card-text>
            <v-chip style="background: #625A42;" small label class="white--text">Behind</v-chip>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="transactions" class="no-gutters">
        <v-col cols="12" class="pb-0">
          <v-card elevation="2" class="text-center">
            <v-card-subtitle class="primary--text font-weight-medium text-subtitle-1 py-2">Transactions Summary</v-card-subtitle>
          </v-card>
        </v-col>
        <v-col md="3" cols="12" sm="6">
          <v-card elevation="2" color="white" class="text-center">
            <v-card-title class="primary--text text-subtitle-1 font-weight-medium justify-center">Total Transactions</v-card-title>
            <v-card-subtitle class="font-weight-bold text-subtitle-2 primary--text">{{ agent_details.user.transactions }} | ₦{{ agent_details.user.amount | formatPrice }}</v-card-subtitle>
          </v-card>
        </v-col>
        <v-col md="3" cols="12" sm="6">
          <v-card elevation="2" color="white" class="text-center">
            <v-card-title class="primary--text text-subtitle-1 font-weight-medium justify-center">This month's transactions</v-card-title>
            <v-card-subtitle class="font-weight-bold text-subtitle-2 primary--text">{{ agent_details.user.monthly_transactions }} | ₦{{ agent_details.user.monthly_amount | formatPrice }}</v-card-subtitle>
          </v-card>
        </v-col>
        <v-col md="3" cols="12" sm="6">
          <v-card elevation="2" color="white" class="text-center">
            <v-card-title class="primary--text text-subtitle-1 font-weight-medium justify-center">This weeks's transactions</v-card-title>
            <v-card-subtitle class="font-weight-bold text-subtitle-2 primary--text">{{ agent_details.user.weekly_transactions }} | ₦{{ agent_details.user.weekly_amount | formatPrice }}</v-card-subtitle>
          </v-card>
        </v-col>
        <v-col md="3" cols="12" sm="6">
          <v-card elevation="2" color="white" class="text-center">
            <v-card-title class="primary--text text-subtitle-1 font-weight-medium justify-center">Today's transactions</v-card-title>
            <v-card-subtitle class="font-weight-bold text-subtitle-2 primary--text">{{ agent_details.user.daily_transactions }} | ₦{{ agent_details.user.daily_amount | formatPrice }}</v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            text
            :class="terminals ? 'primary mr-4 block' : 'inactive mr-4 block'"
            @click="terminals = true, 
            transactions = false, 
            assign_teller = false"
          >Terminals</v-btn>
          
          <v-btn
            text
            @click="transactions = true, 
            terminals = false,  
            assign_teller = false"
            :class="transactions ? 'primary mr-4 block' : 'inactive mr-4 block'"
          >Transactions</v-btn>

          <v-btn
            text
            @click="assign_teller = true, 
            terminals = false, 
            transactions = false"
            :class="assign_teller ? 'primary mr-4 block' : 'inactive mr-4 block'"
          >Assign Teller</v-btn>
        </v-col>

        <v-col v-if="transactions">
          <v-btn
            text
            :class="today ? 'primary mr-4' : 'inactive mr-4'"
            @click="filterToday()"
            id="today"
          >Today</v-btn>

          <v-btn
            text
            @click="filterWeek()"
            :class="this_week ? 'primary mr-4' : 'inactive mr-4'"
          >This Week</v-btn>

          <v-btn
            text
            @click="filterMonth()"
            :class="this_month ? 'primary mr-4' : 'inactive mr-4'"
          >This Month</v-btn>

          <v-btn
            text
            @click="filterAll()"
            :class="all_time ? 'primary mr-4' : 'inactive mr-4'"
          >Since Launch</v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-card-text class="primary--text text-h6 py-0"><small>{{ tabName }}</small></v-card-text>
        <v-col cols="6" class="mt-0">
          <v-text-field
            outlined
            label="Search by Terminal ID, Serial number, Amount, POS type"
            append-icon="search"
            v-model="search"
            color="primary"
            dense
            style="background: #fff; height: 40px"
          ></v-text-field>
        </v-col>

        <v-col 
          v-if="transactions"
          cols="3"
        >
          <v-btn
            elevation="0"
            class="primary--text inactive py-5"
            @click="openFilterDialog({ 'user_id': user.id })"
          ><v-icon>hourglass_bottom</v-icon> Filter result</v-btn>
          <filter-dialog ref="filter"></filter-dialog>
        </v-col>

        <v-row justify="end" align="center" v-if="transactions">
          <v-btn
            text
            class="inactive pa-5 mr-6"
            @click="openReportDialog()"
          >Generate Receipt</v-btn>
        </v-row>
      </v-row>

      <div v-if="terminals">
        <agent-terminals :user_id="agent_details.user.id" :search="search"></agent-terminals>
      </div>  

      <div v-if="transactions">
        <agent-transactions ref="agent_transactions" :user_id="agent_details.user.id" :search="search"></agent-transactions>
      </div>

      <div v-if="assign_teller">
        <Tellers :user_id="agent_details.user.id" :search="search" />
      </div>

      <report-dialog ref="report"></report-dialog>

    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import AgentTerminals from '@/components/pos/agent_terminals'
import AgentTransactions from '@/components/pos/agent_transactions'
import Tellers from '@/components/pos/tellers'
import MiniLoader from '@/components/MiniLoader'
import FilterDialog from '@/components/pos/dialogs/filterDialog'
import ReportDialog from '@/components/pos/dialogs/reportDialog'
export default {
  props: {
    agent: Object,
  },

  components: {
    AgentTerminals,
    AgentTransactions,
    MiniLoader,
    Tellers,
    FilterDialog,
    ReportDialog,
  },
  layout: "dashboard",
  transition: "default",
  data() {
    return {
      today: true,
      this_week: false,
      this_month: false,
      all_time: false,
      search: '',
      loadPage: false,
      date_menu: false,
      loading: false,
      terminals: true,
      transactions: false,
      assign_teller: false,
      pos_terminals: false,
      selected: [],
      filter: null,
      filter_time: '',
      menu1: false,
      dialog: false,
      form: {
        type: null,
      },
      agent_details: this.agent,
    };
  },

  mounted() {
    this.$parent.loadPage = this.loadPage
  },

  methods: {
    ...mapActions('pos', []),

    openFilterDialog(value) {
      this.$refs.filter.openDialog(value)
    },

    filterToday() {
      this.today       = true
      this.this_week   = false
      this.this_month  = false
      this.all_time    = false
      this.filter_time = 'today'
      if (this.filter_time !== '') {
        this.transactions ? this.$refs.agent_transactions.filterTransactions(this.filter_time) : ''
      }
    },

    filterWeek() {
      this.this_week   = true
      this.today       = false
      this.this_month  = false
      this.all_time    = false
      this.filter_time = 'this_week'
      if (this.filter_time !== '') {
        this.transactions ? this.$refs.agent_transactions.filterTransactions(this.filter_time) : ''
      }
    },

    filterMonth() {
      this.this_month = true
      this.today      = false
      this.this_week  = false
      this.all_time   = false
      this.filter_time = 'this_month'
      if (this.filter_time !== '') {
        this.transactions ? this.$refs.agent_transactions.filterTransactions(this.filter_time) : ''
      }
    },

    filterAll() {
      this.all_time   = true
      this.today      = false
      this.this_week  = false
      this.this_month = false
      this.filter_time = 'all_time'
      if (this.filter_time !== '') {
        this.transactions ? this.$refs.agent_transactions.filterTransactions(this.filter_time) : ''
      }
    },

    openReportDialog() {
      let data = {}
      this.transactions ? data.user_id = this.user.id : null
      data.target = 'agent_transactions'
      this.$refs.report.openDialog(data)
    },
  },

  computed: {
    ...mapGetters("auth", ["user"]),

    tabName() {
      let tabName = ''
      this.terminals
      ? tabName = 'Terminals'
      : this.transactions
      ? tabName = 'Transactions'
      // : this.requests
      // ? tabName = 'POS Requests'
      : this.assign_teller
      ? tabName = 'Assign Teller'
      : ''

      return tabName
    },
  }
};
</script>
<style lang="scss">
.v-application .elevation-5 {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25) !important;
}
.v-application .elevation-2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.1),
    0px 2px 2px 0px rgba(0, 0, 0, 0.07), 0px 1px 5px 0px rgba(0, 0, 0, 0.06) !important;
}
.v-application .elevation-0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0),
    0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}
.v-menu__content {
  box-shadow: 0 0.04rem 0.4rem rgba(0, 0, 0, 0.15) !important;
  border: 1px solid #dddddd;
}
.t-color tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.inactive {
  background: #E7E7ED !important;
  color: #171B70 !important;
}

.myTable table thead {
  background: #E7E7ED;
}
table thead tr th {
  color: #171B70 !important;
}
/* webkit solution */
::-webkit-input-placeholder { 
  text-align:right; 
  color: #7A7A7A !important;
}
/* mozilla solution */
input:-moz-placeholder { 
  text-align:right; 
  color: #7A7A7A !important;
}
@media (max-width: 512px) {
  .block {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
