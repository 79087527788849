<template>
  <div>
    <v-dialog
      max-width="450"
      v-model="dialog"
    >
      <v-card>
        <v-card-actions
          class="d-flex justify-end"
        >
          <v-btn
            icon
            color="primary"
            @click="closeDialog()"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-img
          :lazy-src="document"
          :src="document"
        ></v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      document: null
    }
  },

  methods: {
    openDialog(document) {
      this.document = document
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
    }
  },
}
</script>