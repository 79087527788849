<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="450"
    >
      <v-card
        class="px-10 py-8"
      >
        <v-card-title class="text-h6 primary--text pt-0">
          How Will You Like To Fund? 
        </v-card-title>
        <v-card-subtitle class="black--text">Select Funding Option</v-card-subtitle>
        <input type="text" style="opacity: 0; position: absolute" :value="wallet" id="wallet">
        <input type="text" style="opacity: 0; position: absolute" :value="vfd" id="vfd">
        <v-toolbar color="white" elevation="1" dense>
          <small class="secondary--text">Transave ID</small>
          <v-spacer></v-spacer>
          <small class="primary--text">{{ wallet }}</small>
          <v-spacer></v-spacer>
          <v-btn
            class="rounded-lg"
            small
            elevation="0"
            @click="copyWallet('wallet')"
          ><small>copy</small></v-btn>
        </v-toolbar>
        <v-toolbar color="white" class="ma-3 mx-0" elevation="1" dense>
          <small class="secondary--text">VFD Bank</small>
          <v-spacer></v-spacer>
          <small class="primary--text">{{ vfd }}</small>
          <v-spacer></v-spacer>
          <v-btn
            class="rounded-lg"
            small
            elevation="0"
            @click="copyVfd('vfd')"
          ><small>copy</small></v-btn>
        </v-toolbar>
        <label for="" class="primary--text"><small>Select funding type</small></label>
        <v-select
          dense
          outlined
          v-model="form.type"
          :rules="[(v) => !!v || 'This field is required']"
          :items="[
            { text: 'USSD', value: 'ussd' },
            { text: 'Transfer', value: 'transfer' }
          ]"
        ></v-select>
        <v-text-field
          dense
          outlined
          v-model="form.amount"
          type="number"
          :rules="[(v) => !!v || 'This field is required']"
        ></v-text-field>
        <v-card-actions class="d-block px-0">
          <v-btn
            block
            large
            color="primary"
            elevation="0"
            @click="processFunds()"
          >Fund Wallet</v-btn>
          <v-btn
            text
            block
            depressed
            @click="closeDialog()"
          ><small>Back?</small></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      dialog: false,
      form: {},
    }
  },


  computed: {
    ...mapGetters('auth', ['user']),

    wallet() {
      return this.user.accounts.find(
        x => x.account_name == 'Wallet ID'
      ).account_number
    },

    vfd() {
      return this.user.accounts.find(
        x => x.account_name !== 'Wallet ID'
      ).account_number
    }
  },

  methods: {
    openDialog() {
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false
    },

    processFunds() {
      
    },

    copyWallet(id) {
      document.getElementById(id).select()
      document.execCommand('copy')
      this.$toast.success('Wallet copied')
    },

    copyVfd(id) {
      document.getElementById(id).select()
      document.execCommand('copy')
      this.$toast.success('VFD account copied')
    }
  },
}
</script>

<style scoped>
  .v-btn:before {
    opacity: 0 !important;
  }
  .v-ripple__container {
    opacity: 0 !important;
  }
</style>