<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="450"
    >
      <v-card
        color="white"
        class="py-8 px-10"
      >
        <v-toolbar flat class="d-flex justify-center">
          <v-icon color="secondary" size="80">task_alt</v-icon>
        </v-toolbar>
        <v-card-title class="primary--text text-h6 d-flex justify-center">
          Request Submitted Successfully
        </v-card-title>
        <small>
          Your POS will be ready for collection in three weeks.
          Kindly check your notification for collection date and Address
        </small>
        <v-card-actions class="d-flex justify-center mt-7">
          <v-btn
            text
            class="grey lighten-2 px-5 grey--text"
            @click="closeDialog()"
          >Do it Later</v-btn>
          <v-btn
            text
            class="primary px-5"
            @click="fundWallet()"
          >Fund your wallet</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <fund-dialog ref="fund"></fund-dialog>
  </div>
</template>

<script>
import FundDialog from './fundDialog'
export default {
  components: {
    FundDialog
  },

  data() {
    return {
      dialog: false
    }
  },

  methods: {
    openDialog() {
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false
    },

    fundWallet() {
      this.closeDialog()
      this.$refs.fund.openDialog()
    }
  }
}
</script>

<style scoped>
  .text-h6 {
    font-family: Poppins;
    font-style: Medium;
    font-size: 18px !important;
    line-height: 33px;
    text-align: Center;
    vertical-align: Center;
    color: #171B70;
  }
</style>
