<template>
    <div>
        <v-row class="grey lighten-5 pa-0">
            <v-col cols="12" sm="5" md="5">
                <v-stepper v-model="step">
                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <v-card flat class="pa-0">
                                <v-card-title class="primary--text font-weight-bold pl-4 mb-2 text-h6 text-break">Basic Information</v-card-title>
                                <v-card-subtitle class="text-muted">
                                    Kindly fill up the follwing information to be eligible for POS request.
                                    Note: It is a one time information to verify your identity.
                                </v-card-subtitle>
                                <img class="d-none d-sm-block d-md-block mt-4" width="100%" src="@/assets/icons/card.png" alt="">
                            </v-card>
                        </v-stepper-content>
                        <v-stepper-content step="2">
                            <v-card flat class="pa-0">
                                <v-card-title class="primary--text font-weight-bold pl-4 mb-2 text-h6 text-break">Business Information and Documents</v-card-title>
                                <v-card-subtitle class="text-muted">
                                    Kindly fill up the follwing information to be eligible for POS request.
                                    Note: It is a one time information to verify your identity.
                                </v-card-subtitle>
                                <img class="d-none d-sm-block d-md-block mt-4" width="100%" src="@/assets/icons/card.png" alt="">
                            </v-card>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-col>
            <v-col cols="12" sm="7" md="7">
                <v-stepper v-model="step">
                    <v-stepper-items>
                        <v-stepper-content step="1" class="form">
                            <v-card class="pa-0 lighten-5 rounded-lg">
                                <v-form lazy-validation ref="posupdate">
                                    <div class="primary--text">Agent Name</div>
                                    <v-text-field
                                        class="my-0 py-0"
                                        outlined
                                        dense
                                        readonly
                                        type="text"
                                        v-model="form.agent_name"
                                        :rules="[(v) => !!v || 'Please fill in your fullname']"
                                    ></v-text-field>
                                    <!-- <div class="primary--text">Gender</div>
                                    <v-select
                                        outlined
                                        label="Gender"
                                        v-model="form.gender"
                                        :items="[
                                            { text: 'Male', value: 'Male' },
                                            { text: 'Female', value: 'Female'}
                                        ]"
                                        :rules="[(v) => !!v || 'Please select one']"
                                    ></v-select> -->
                                    <div class="primary--text">Email Address</div>
                                    <v-text-field
                                        class="my-0 py-0"
                                        dense
                                        readonly
                                        outlined
                                        type="email"
                                        v-model="form.email"
                                        :rules="[(v) => !!v || 'Please provide your email']"
                                    ></v-text-field>
                                    <div class="primary--text">Phone Number</div>
                                    <v-text-field
                                        class="my-0 py-0"
                                        dense
                                        readonly
                                        outlined
                                        v-model="form.phone_number"
                                        type="number"
                                        :rules="[(v) => !!v || 'Please fill in your phone number']"
                                    ></v-text-field>
                                    <div class="primary--text">Occupation</div>
                                    <v-text-field
                                        class="my-0 py-0"
                                        dense
                                        outlined
                                        type="text"
                                        v-model="form.occupation"
                                        :rules="[(o) => !!o || 'Please fill in your occupation']"
                                    ></v-text-field>
                                    <!-- <div class="primary--text">Date of birth</div>
                                    <v-menu
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        max-width="290"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                append-icon="event_note"
                                                outlined
                                                :value="form.dob"
                                                placeholder="Date"
                                                readonly
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            light
                                            v-model="form.dob"
                                        ></v-date-picker>
                                    </v-menu> -->
                                    <div class="primary--text">BVN</div>
                                    <v-text-field
                                        class="my-0 py-0"
                                        dense
                                        outlined
                                        disabled
                                        v-model="user.bvn"
                                        type="number"
                                        :rules="[(b) => !!b || 'This field is required']"
                                    ></v-text-field>
                                    <div class="primary--text">Means of Identification</div>
                                    <v-select
                                        class="my-0 py-0"
                                        dense
                                        readonly
                                        outlined
                                        v-model="form.means_of_id"
                                        :items="[
                                            { text: 'ID Card', value: 'id_card' },
                                            { text: 'Voters Card', value: 'voters_card' },
                                            { text: 'National ID', value: 'national_id' },
                                            { text: 'Drivers License', value: 'drivers_license'},
                                            { text: 'International Passport', value: 'international_passport' }
                                        ]"
                                        label="Means of Identification"
                                        :rules="[(v) => !!v || 'This field is required']"
                                    ></v-select>
                                    <div class="primary--text">ID Number</div>
                                    <v-text-field
                                        outlined
                                        readonly
                                        dense
                                        v-model="form.id_number"
                                        :rules="[(v) => !!v || 'This field is required']"
                                    ></v-text-field>
                                    <div class="primary--text" @click="viewDoc(kyc.id_card_url)">
                                        Identification Document 
                                        <small class="pointer">(click to view)</small>
                                    </div>
                                    <v-img
                                        :lazy-src="kyc.id_card_url"
                                        :src="kyc.id_card_url"
                                        width="100"
                                        @click="viewDoc(kyc.id_card_url)"
                                        class="pointer"
                                    ></v-img>
                                    <!-- <v-file-input
                                        outlined
                                        v-model="form.identification_doc"
                                        label="Upload file"
                                        :rules="[(v) => !!v || 'This field is required']"
                                        prepend-icon=""
                                        prepend-inner-icon="attach_file"
                                        placeholder="Upload file"
                                        @change="identificationDocs"
                                    ></v-file-input> -->
                                    <div class="primary--text mt-4" @click="viewDoc(kyc.id_card_url)">
                                        Utility Bill Document 
                                        <small class="pointer">(click to view)</small>
                                    </div>
                                    <v-img
                                        :lazy-src="kyc.proof_of_address_url"
                                        :src="kyc.proof_of_address_url"
                                        width="100"
                                        class="mb-4 pointer"
                                        @click="viewDoc(kyc.proof_of_address_url)"
                                    ></v-img>
                                    <!-- <v-file-input
                                        outlined
                                        v-model="form.utility_billdoc"
                                        label="Upload file"
                                        :rules="[(v) => !!v || 'This field is required']"
                                        prepend-icon=""
                                        prepend-inner-icon="attach_file"
                                        placeholder="Upload file"
                                        @change="billDocs"
                                    ></v-file-input> -->
                                    <!-- <div class="primary--text">State</div>
                                    <v-text-field
                                        class="my-0 py-0"
                                        dense
                                        outlined
                                        v-model="form.state"
                                        label="State"
                                        :rules="[(v) => !!v || 'This field is required']"
                                    ></v-text-field>
                                    <div class="primary--text">LGA</div>
                                    <v-text-field
                                        class="my-0 py-0"
                                        dense
                                        outlined
                                        v-model="form.lga"
                                        label="Local Government Area"
                                        :rules="[(v) => !!v || 'This field is required']"
                                    ></v-text-field>
                                    <div class="primary--text">Town</div>
                                    <v-text-field
                                        class="my-0 py-0"
                                        dense
                                        outlined
                                        v-model="form.city"
                                        label="Town"
                                        :rules="[(v) => !!v || 'This field is required']"
                                    ></v-text-field> -->
                                    <div class="primary--text">Home Address</div>
                                    <v-text-field
                                        class="my-0 py-0"
                                        dense
                                        readonly
                                        outlined
                                        v-model="form.home_address"
                                        :rules="[(v) => !!v || 'Please provide your home address']"
                                    ></v-text-field>
                                    <div class="primary--text">Nearest Bus Stop</div>
                                    <v-text-field
                                        class="my-0 py-0"
                                        dense
                                        outlined
                                        v-model="form.nearest_bus_stop"
                                        :rules="[(v) => !!v || 'Please provide your nearest bus stop/land mark']"
                                    ></v-text-field>
                                    <v-btn
                                        color="grey lighten-3"
                                        class="mr-3"
                                        x-large
                                        @click="cancelRequest()"
                                    >Cancel</v-btn>
                                    <v-btn
                                        color="primary"
                                        :loading="loading"
                                        x-large
                                        @click="$refs.posupdate.validate() ? businessForm() : null"
                                    >Proceed</v-btn>
                                </v-form>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-content step="2" class="form">
                            <v-card class="pa-0 lighten-5 rounded-lg" elevation="0">
                                <v-form lazy-validation ref="business">
                                    <div v-for="(location, index) in form.pos_locations" :key="index">
                                        <v-row>
                                            <v-col v-if="index == 0" cols="5" md="3" sm="4" class="pb-0 mb-0">
                                                <label for="" class="primary--text"><small>No of POS</small></label>
                                                <v-text-field
                                                    class="py-0 my-0"
                                                    dense
                                                    outlined
                                                    type="number"
                                                    v-model="form.no_of_pos"
                                                    :rules="[(v) => !!v || 'This field is required']"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col class="mb-0 pb-0" :cols="index == 0 ? '7' : '9'" :sm="index == 0 ? '8' : '10'" :md="index == 0 ? '9' : '10'">
                                                <label for="" class="primary--text"><small>Location {{ index + 1 }} (State)</small></label>
                                                <v-text-field
                                                    class="my-0 py-0"
                                                    dense
                                                    v-model="location.state"
                                                    outlined
                                                    :rules="[(v) => !!v || 'This field is required']"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col v-if="index > 0" cols="1" sm="1" md="1" align-self="center">
                                                <v-btn icon class="primary" x-small>
                                                    <v-icon color="white" size="15" @click.prevent="remove(index)">close</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="6" class="py-0 my-0">
                                                <label for="" class="primary--text"><small>Location {{ index + 1 }} (City)</small></label>
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    v-model="location.city"
                                                    :rules="[(v) => !!v || 'This field is required']"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="6" class="py-0 my-0">
                                                <label for="" class="primary--text"><small>Location {{ index + 1 }} (Address)</small></label>
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    v-model="location.address"
                                                    :rules="[(v) => !!v || 'This field is required']"
                                                ></v-text-field>
                                            </v-col> 
                                        </v-row>
                                    </div>

                                    <div class="col-md-12 text-center">
                                        <v-btn class="primary--text" :ripple="false" depressed text @click.prevent="addMore()"><v-icon>add</v-icon> Add More</v-btn>
                                    </div>

                                    <label for="" class="primary--text"><small>Do you have a Business</small></label>
                                    <v-select
                                        outlined
                                        class="py-0 my-0"
                                        dense
                                        :items="[
                                            {text: 'Yes', value: 'Yes'},
                                            {text: 'No', value: 'No'}
                                        ]"
                                        v-model="form.business"
                                    ></v-select>
                                    <div v-if="form.business == 'Yes'">
                                        <label for="" class="primary--text"><small>Business Name</small></label>
                                        <v-text-field
                                            class="py-0 my-0"
                                            dense
                                            outlined
                                            v-model="form.business_name"
                                            label="Business Name"
                                            :rules="[(v) => !!v || 'Please provide your Business Name']"
                                        ></v-text-field>
                                        <!-- <label for="" class="primary--text"><small>Business Registration Number</small></label>
                                        <v-text-field
                                            class="py-0 my-0"
                                            dense
                                            outlined
                                            v-model="form.business_reg_num"
                                            label="Business registration number"
                                            :rules="[(v) => !!v || 'Please provide your Business registration number']"
                                        ></v-text-field> -->
                                        <label for="" class="primary--text"><small>Business Type</small></label>
                                        <v-text-field
                                            class="py-0 my-0"
                                            dense
                                            outlined
                                            v-model="form.business_type"
                                            label="Business Type"
                                            :rules="[(v) => !!v || 'What type of business do you run?']"
                                        ></v-text-field>
                                    </div>
                                    <label for="" v-if="form.business == 'Yes'" class="primary--text"><small>Business License/CAC</small></label>
                                    <v-file-input
                                        class="py-0 my-0"
                                        dense
                                        outlined
                                        v-model="form.business_license"
                                        label="Upload file"
                                        :rules="[(v) => !!v || 'This field is required']"
                                        placeholder="upload file"
                                        prepend-icon=""
                                        prepend-inner-icon="attach_file"
                                        accept="images/*, application/pdf"
                                        v-if="form.business == 'Yes'"
                                        @change="business_license"
                                    ></v-file-input>
                                    <label for="" class="primary--text"><small>Other Documents (Optional)</small></label>
                                    <v-file-input
                                        class="py-0 my-0"
                                        dense
                                        outlined
                                        v-model="form.other_doc"
                                        label="Upload file"
                                        prepend-icon=""
                                        prepend-inner-icon="attach_file"
                                        placeholder="Upload file"
                                        @change="otherDocs"
                                        accept="image/*, application/pdf"
                                    ></v-file-input>
                                    <v-col v-if="terms !== true">
                                        <a href="terms-and-conditions" class="text-subtitle-2" @click.prevent="$refs.terms.openDialog()">Accept Terms And Conditions</a>
                                    </v-col>
                                    <v-btn
                                        class="mr-2 primary--text"
                                        x-large
                                        @click="back()"
                                    >Back</v-btn>
                                    <v-btn
                                      :disabled="terms !== true"
                                      :loading="loading"
                                      @click="$refs.business.validate() ? posRequest() : null"
                                      color="primary"
                                      x-large
                                    >Submit</v-btn>
                                    <!-- <v-btn
                                        color="primary"
                                        :loading="loading"
                                        x-large
                                        @click="$refs.business.validate() ? $refs.success.openDialog() : null"
                                    >Submit</v-btn> -->
                                </v-form>
                            </v-card>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-col>
        </v-row>
        
        <docs-dialog ref="docs"></docs-dialog>
        <success-dialog ref="success"></success-dialog>
        <error-dialog ref="errors" :errors="errors"></error-dialog>
        <update-dialog ref="update"></update-dialog>
        <terms-dialog ref="terms"></terms-dialog>

    </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import DocsDialog from '@/components/pos/dialogs/docsDialog'
import SuccessDialog from '@/components/pos/dialogs/successDialog'
import ErrorDialog from '@/components/pos/dialogs/errorsDialog'
import UpdateDialog from '@/components/pos/dialogs/updateDialog'
import TermsDialog from '@/components/pos/dialogs/termsDialog'
export default {
    components: {
        SuccessDialog,
        DocsDialog,
        ErrorDialog,
        UpdateDialog,
        TermsDialog,
    },

    props: {
        kyc_details: Object,
    },
    
    data() {
        return {
            valid: true,
            form: {
                dob: new Date().toISOString().substr(0, 10),
                agent_name: '',
                identification_doc: null,
                utility_billdoc: null,
                other_doc: null,
                phone_number: '',
                email: '',
                maiden_name: '',
                gender: '',
                // state: '',
                // city: '',
                // lga: '',
                home_address: '',
                bvn: '',
                means_of_id: '',
                id_number: '',
                pos_locations: [{}],
            },
            loading: false,
            step: 1,
            // done: false,
            menu: false,
            kyc: this.kyc_details,
            errors: {},
            pos_locations: [],
            terms: false,
        }
    },

    mounted() {
        this.form.agent_name    = this.kyc.first_name +' '+ this.kyc.last_name
        this.form.gender        = this.kyc.user.sex
        // this.form.identification_doc = this.kyc.id_card_url
        // this.form.utility_billdoc = this.kyc.proof_of_address_url
        this.form.email         = this.kyc.user.email
        this.form.phone_number  = this.kyc.user.phone
        this.form.dob           = this.kyc.user.dob
        this.form.bvn           = this.kyc.user.bvn
        // this.form.state         = this.kyc.state.state
        // this.form.lga           = this.kyc.lga.lga
        // this.form.city          = this.kyc.city
        this.form.home_address  = this.kyc.home_address
        this.form.id_number     = this.kyc.id_card_number
        this.form.means_of_id   = this.means_of_id
    },

    methods: {
        businessForm() {
            this.step = 2;
            window.scrollTo(0, 0)
        },

        back() {
            window.scrollTo(0, 0);
            this.step = 1;
            this.terms = false;
        },

        async posRequest() {
            // this.$parent.dashboard = true
            this.loading        = true
            let has_business    = this.form.business == 'Yes' ? 1 : 0;
            let formData = new FormData();
            // formData.append('utility_doc', this.form.utility_billdoc);
            // formData.append('id_doc', this.form.identification_doc);
            formData.append('business_license', this.form.business_license);
            if (this.form.other_doc) {
                formData.append('other_doc', this.form.other_doc);
            }
            // formData.append('first_name', first_name);
            // formData.append('last_name', last_name);
            // formData.append('email', this.form.email);
            // formData.append('phone_number', this.form.phone_number);
            // formData.append('maiden_name', this.form.maiden_name);
            // formData.append('gender', this.form.gender);
            // formData.append('state', this.form.state);
            // formData.append('city', this.form.city);
            // formData.append('lga', this.form.lga);
            // formData.append('home_address', this.form.home_address);
            // formData.append('bvn', this.form.bvn);
            formData.append('business_name', this.form.business_name);
            formData.append('business_type', this.form.business_type);
            formData.append('business_reg_num', this.form.business_reg_num);
            formData.append('has_business', has_business);
            formData.append('utility_billdoc', this.kyc.proof_of_address_url);
            formData.append('identification_doc', this.kyc.id_card_url);
            // formData.append('dob', this.form.dob);
            formData.append('nearest_bus_stop', this.form.nearest_bus_stop);
            formData.append('means_of_id', this.form.means_of_id);
            formData.append('occupation', this.form.occupation);
            formData.append('id_number', this.form.id_number);
            formData.append('no_of_pos', this.form.no_of_pos);
            formData.append('pos_locations', JSON.stringify(this.form.pos_locations));
            // formData.append('middle_name', null);
            
            try {
                let response = await this.$store.dispatch('pos/posrequest', formData);
                this.loading = false
                this.$toast.success(response.message);
                // this.done           = true;
                setTimeout(() => {
                    this.$parent.basic  = false
                    this.$parent.agent  = true
                }, 5000);
            } catch (error) {
                this.loading    = false;
                if ((error.response.status == 422) && (typeof(error.response.data.message) !== 'string')) {
                    this.errors     = Object.assign({}, error.response.data.message);
                    this.$refs.errors.openDialog();
                } else {
                    this.$toast.error(error.response.data.message);
                }
            }
        },

        billDocs() {
            if (this.form.utility_billdoc) {
                let file = this.form.utility_billdoc;
                this.form.billSrc = URL.createObjectURL(file);
            }
        },

        identificationDocs() {
            if (this.form.identification_doc) {
                let file = this.form.identification_doc
                this.form.idSrc = URL.createObjectURL(file)
            }
        },

        business_license() {
            if (this.form.business_license) {
                let file = this.form.business_license
                this.form.businessSrc = URL.createObjectURL(file)
            }
        },

        otherDocs() {
            if (this.form.other_doc) {
                let file = this.form.other_doc
                this.form.ortherSrc = URL.createObjectURL(file)
            }
        },

        closeDialog() {
            this.dialog = false
        },

        viewDoc(document) {
            this.$refs.docs.openDialog(document)
        },

        addMore() {
            this.form.pos_locations.push(Vue.util.extend({}, this.pos_locations));
        },

        remove(index) {
            this.form.pos_locations.splice(index, 1);
            this.pos_locations.splice(index, 1);
        },

        cancelRequest() {
            this.$parent.agent = true;
            this.$parent.basic = false;
            window.scrollTo(0, 0)
        }
    },

    computed: {
        ...mapGetters('auth', ['user', 'user2']),

        means_of_id() {
            let id = null
            switch (this.kyc.id_card_type_id) {
                case 1:
                    id = 'national_id';
                    break;
                case 2:
                    id = 'international_passport';
                    break;
                case 3:
                    id = 'drivers_license';
                    break;
                case 4:
                    id = 'vorters_card';
                    break;
                default:
                    id = ''
                    break;
            }
           
            return id
        }
    },
}
</script>
<style lang="scss" scoped>
    .savings-card {
        .heading {
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            /* identical to box height */
            color: #171b70;
        }
    }
    .pointer {
        cursor: pointer;
    }
</style>
