<template>
	<div>
		<v-row>
			<v-col cols="12">
				<v-card outlined>
					<v-data-table
						v-model="selected"
						:headers="terminalHeaders"
						:items="all_terminals"
						:single-select="false"
						:search="search"
						item-key="id"
						show-select 
						class="elevation-1 myTable pointer"
						@click:row="terminalDetails"
						:loading="fetchTerminals"
						loading-text="Loading data... Please wait"
					>
						<template v-slot:[`header.data-table-select`]="{ props, on }">
							<v-simple-checkbox
								:value="props.value || props.indeterminate"
								v-on="on"
								:indeterminate="props.indeterminate"
								color="white"
								:light="true"
								:ripple="false"
							/>
						</template>
						<template v-slot:[`header.actions`]>
							<v-menu class="ma-0" offset-y>
								<template v-slot:activator="{ on, dropdown }">
									<v-btn
										icon
										color="primary"
										v-bind="dropdown"
										v-on="on"
									>
										<v-icon>mdi-dots-horizontal</v-icon>
									</v-btn>
								</template>

								<v-list>
									<v-list-item link>
										<v-list-item-title
											class="primary--text soft-bold"
											block
											@click="$router.push({ name: 'PosTeller'})"
										>
											<small>Assign Teller</small>
										</v-list-item-title>
									</v-list-item>
									<v-list-item link>
										<v-list-item-title
											class="primary--text soft-bold"
											block
											@click="rejectReuest(item)"
										>
											<small>Remove Teller</small>
										</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</template>

						<template v-slot:[`item.actions`]="{ item }">
							<v-menu>
								<template v-slot:activator="{ on, dropdown }">
									<v-btn
										icon
										color="primary"
										v-bind="dropdown"
										v-on="on"
									>
										<v-icon>mdi-dots-horizontal</v-icon>
									</v-btn>
								</template>

								<v-list>
									<v-list-item link>
										<v-list-item-title
											class="primary--text soft-bold"
											@click="acceptReuest(item)"
										>
											<small>Assign Teller</small>
										</v-list-item-title>
									</v-list-item>
									<v-list-item link>
										<v-list-item-title
											class="primary--text soft-bold"
											@click="rejectReuest(item)"
										>
											<small>Remove Teller</small>
										</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</template>
						<template v-slot:[`item.numbers`]="{ index }">
							<div>{{ index + 1 }}</div>
						</template>
						<template v-slot:[`item.fullname`]="{ item }">
							<div>{{ item.first_name + ' ' + item.last_name }}</div>
						</template>
						<template v-slot:[`item.date`]="{ item }">
							<div>{{ item.created_at | formatDate }}</div>
						</template>
						<!-- <template v-slot:[`item.status`]="{ item }">
							<v-chip label color="#ECE1BF">{{ item.status }}</v-chip>
						</template> -->
					</v-data-table>
				</v-card>
			</v-col>
		</v-row>
		<!-- <assign-agent ref="assignAgent"></assign-agent> -->
	</div>
</template>

<script>
import { mapActions } from 'vuex'
// import assignAgent from './dialogs/assignAgent'
export default {
	components: {
		// assignAgent,
	},

	props: {
		user_id: String,
		search: String,
	},

	data() {
		return {
			loading: false,
			selected: [],
			terminalHeaders: [
				{ 
					text: 'S/N', 
					value: 'numbers', 
					sortable: false, 
					orderable: false,
				},
				{ text: 'Reference', value: 'reference' },
				{ text: 'Type', value: 'type' },
				{ text: 'Date', value: 'created_at' },
				{ text: 'Total Amount', value: 'total_amount' },
				// { text: 'Number of Transactions', value: 'num_of_transactions' },
				// { text: 'Agent Assigned', value: 'agent_assigned' },
				// { text: 'POS Type', value: 'pos_type'},
				// { text: 'Date Created', value: 'date' },
				{ text: 'terminal', value: 'terminal' },
				// { text: 'Terminal Tellers', value: 'terminal_teller' },
				// { text: 'Status', value: 'status' },
				// { text: 'Actions', value: 'actions', sortable: false, orderable: false }
			],
			all_terminals: [],
			fetchTerminals: false,
		}
	},

	mounted() {
		this.agentTerminals()
	},

	methods: {
		...mapActions('pos', [
			'agentterminals'
		]),

		assignAgent(item) {
			console.log(item)
			this.$refs.assignAgent.openDialog()
		},

		async agentTerminals() {
			// this.fetchTerminals = true
			// let user_id = this.user_id
			// try {
			// 	let response = await this.agentterminals(user_id)
			// 	this.fetchTerminals = false
			// 	this.all_terminals = response.data
			// } catch (error) {
			// 	this.fetchTerminals = false
			// }
		},

		terminalDetails(row) {
			this.all_terminals.map((item, index) => {
				item.selected = item === row
				this.$set(this.all_terminals, index, item)
			})

			this.$router.push({ name: 'TerminalDetails', params: { terminal_id: row.terminalId }})
		}
	}
}
</script>

<style scoped>
	.pointer >>> tbody tr :hover {
		cursor: pointer;
	}
</style>