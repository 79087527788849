<template>
	<div>
		<v-row>
			<v-col cols="12">
				<v-card outlined>
					<v-data-table
						v-model="selected"
						:headers="terminalHeaders"
						:items="all_transactions"
						:single-select="false"
						item-key="id"
						show-select 
						:search="search"
						class="elevation-1 myTable"
						:loading="fetchTransactions"
						loading-text="Loading data... Please wait"
					>
						<template v-slot:[`header.data-table-select`]="{ props, on }">
							<v-simple-checkbox
								:value="props.value || props.indeterminate"
								v-on="on"
								:indeterminate="props.indeterminate"
								color="white"
								:light="true"
								:ripple="false"
							/>
						</template>
						<template v-slot:[`header.actions`]>
							<v-menu class="ma-0">
								<template v-slot:activator="{ on, dropdown }">
									<v-btn
										icon
										color="white"
										v-bind="dropdown"
										v-on="on"
									>
										<v-icon>mdi-dots-horizontal</v-icon>
									</v-btn>
								</template>

								<v-list>
									<v-list-item>
										<v-list-item-title>
											<v-btn
												text
												color="primary"
												block
												@click="acceptReuest(item)"
											>Suspend</v-btn>
										</v-list-item-title>
									</v-list-item>
									<v-list-item>
										<v-list-item-title>
											<v-btn
												text
												color="primary"
												block
												@click="rejectReuest(item)"
											>Delete</v-btn>
										</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</template>

						<template v-slot:[`item.actions`]="{ item }">
							<v-menu>
								<template v-slot:activator="{ on, dropdown }">
									<v-btn
										icon
										color="primary"
										v-bind="dropdown"
										v-on="on"
									>
										<v-icon>mdi-dots-horizontal</v-icon>
									</v-btn>
								</template>

								<v-list>
									<v-list-item>
										<v-list-item-title 
											style="cursor: pointer"
											text
											class="primary--text"
											@click="agentSettings()"
										>Fund Terminal</v-list-item-title>
									</v-list-item>
									<v-list-item>
										<v-list-item-title 
											style="cursor: pointer"
											text
											class="primary--text"
											@click="assignAgent(item)"
										>Stop Terminal</v-list-item-title>
									</v-list-item>
									<v-list-item>
										<v-list-item-title 
											style="cursor: pointer"
											text
											class="primary--text"
											@click="processReuest(item)"
										>Delete</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</template>
						<template v-slot:[`item.numbers`]="{ index }">
							<div>{{ index + 1 }}</div>
						</template>
						<template v-slot:[`item.transactionDate`]="{ item }">
							<div>{{ item.transactionDate | formatDate }}</div>
						</template>
						<template v-slot:[`item.amount`]="{ item }">
							<div>₦ {{ item.amount | formatPrice }}</div>
						</template>
						<template v-slot:[`item.commission_amount`]="{ item }">
							<div>₦ {{ item.commission_amount }}</div>
						</template>
						<template v-slot:[`item.reversal`]="{ item }">
							<div>{{ item.reversal == 1 ? 'Yes' : 'No' }}</div>
						</template>
					</v-data-table>
				</v-card>
			</v-col>
		</v-row>
		<!-- <assign-agent ref="assignAgent"></assign-agent> -->
	</div>
</template>

<script>
import { mapActions } from 'vuex'
// import assignAgent from './dialogs/assignAgent'
export default {
	components: {
		// assignAgent,
	},

	props: {
		user_id: String,
		search: String,
	},

	data() {
		return {
			loading: false,
			selected: [],
			terminalHeaders: [
				{ 
					text: 'S/N', 
					value: 'numbers', 
					sortable: false, 
					orderable: false,
				},
				{ text: 'Reference', value: 'reference' },
				{ text: 'Type', value: 'transactionType' },
				{ text: 'Date', value: 'transactionDate' },
				{ text: 'Total Amount', value: 'amount' },
				// { text: 'Recipient', value: 'total_transactions_amount' },
				{ text: 'Terminal', value: 'terminalId' },
				{ text: 'Commission', value: 'commission_amount' },
				{ text: 'Reversal', value: 'reversal' },
				// { text: 'Filter', value: 'actions', sortable: false, orderable: false }
			],
			all_transactions: [],
			fetchTransactions: false,
		}
	},

	mounted() {
		// this.agentTransactions()
		this.$parent.filterToday()
		this.$root.$on('filteredtransactions', (data) => {
			let res = data.filter(item => {
				if (item.id) {
					return item
				}
			})
			this.all_transactions = res

			let trns = data.filter(item => {
				if (item.transactions) {
					return item
				}
			})
			
			if (trns.length) {
				trns.forEach(item => {
					this.$parent.agent_details.user.transactions 					= item.transactions
					this.$parent.agent_details.user.amount								= item.amount
					this.$parent.agent_details.user.daily_amount					= 0
					this.$parent.agent_details.user.weekly_amount					= 0
					this.$parent.agent_details.user.monthly_amount				= 0
					this.$parent.agent_details.user.daily_transactions 		= 0
					this.$parent.agent_details.user.weekly_transactions		= 0
					this.$parent.agent_details.user.monthly_transactions	= 0
				})
			} else {
				this.$parent.agent_details.user.transactions 					= 0
				this.$parent.agent_details.user.amount								= 0
				this.$parent.agent_details.user.daily_amount					= 0
				this.$parent.agent_details.user.weekly_amount					= 0
				this.$parent.agent_details.user.monthly_amount				= 0
				this.$parent.agent_details.user.daily_transactions 		= 0
				this.$parent.agent_details.user.weekly_transactions		= 0
				this.$parent.agent_details.user.monthly_transactions	= 0
			}
		})
	},

	methods: {
		...mapActions('pos', [
			'agenttransactions',
			'filter_agent_transactions',
		]),

		assignAgent(item) {
			console.log(item)
			this.$refs.assignAgent.openDialog()
		},

		// async agentTransactions() {
		// 	this.fetchTransactions = true
		// 	let user_id = this.user_id
		// 	try {
		// 		let response = await this.agenttransactions(user_id)
		// 		this.fetchTransactions = false
		// 		this.all_transactions = response.data
		// 	} catch (error) {
		// 		this.fetchTransactions = false
		// 	}
		// },

		async filterTransactions(value) {
			this.fetchTransactions = true
			let payload = {
				user_id: this.user_id,
				filter: value,
				target: 'agent_transactions'
			}
			try {
				let response = await this.filter_agent_transactions(payload)
				this.fetchTransactions = false
				this.all_transactions = response.data.transactions
			} catch (error) {
				this.fetchTransactions = false
				this.$toast.error(error.response.data.message)
			}
		}
	}
}
</script>