<template>
  <div>
    <v-dialog
      max-width="450"
      v-model="dialog"
    >
      <v-card color="white" class="pa-4">
        <div class="d-flex justify-content-between">
          <v-btn
            @click="clickedFund"
            text
            :color="(fund) ? 'primary' : 'grey'"
          >Fund Terminal</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="clickedWithdraw"
            text
            :color="(withdraw) ? 'primary' : 'grey'"
            class="float-right"
          >Withdraw Fund</v-btn>
        </div>
        <v-divider class="primary mb-3"></v-divider>
        <v-form lazy-validation ref="fundWithdraw">
          <div v-if="fund">
            <label class="primary--text">Enter amount to fund</label>
            <v-text-field
              outlined
              dense
              v-model="form.amount"
              :rules="[(v => !!v || 'Please enter amount to fund')]"
            ></v-text-field>
          </div>
          <div v-else>
            <label class="primary--text">Enter amount to withdraw</label>
            <v-text-field
              outlined
              dense
              v-model="form.amount"
              :rules="[(v => !!v || 'Please enter amount to withdraw')]"
            ></v-text-field>
          </div>
        </v-form>
        <v-card-actions class="d-flex pa-0">
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog" outlined color="secondary">Close</v-btn>
          <v-btn
            @click="fundTerminal"
            v-if="fund"
            color="primary"
            :loading="loading"
          >Fund</v-btn>
          <v-btn
            @click="withdrawFund"
            v-if="withdraw"
            color="primary"
            :loading="loading"
          >Withdraw</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
    import { mapActions } from 'vuex';
    export default {
        name: "walletDialog",
        props: {
            item: {
                type: Object,
                default: {},
            }
        },

        data() {
            return {
                dialog: false,
                fund: true,
                withdraw: false,
                form: {},
                loading: false,
            };
        },

        methods: {
            ...mapActions('pos', [
                'fund_terminal',
                'withdraw_fund',
            ]),

            clickedFund() {
                this.withdraw = false;
                this.fund = true;
            },

            clickedWithdraw() {
                this.withdraw = true;
                this.fund = false;
            },

            async fundTerminal() {
                this.form.terminal_id = this.item.terminalId;
                this.loading = true;
                try {
                    let response = await this.fund_terminal(this.form);
                    this.$toast.success(response.message);
                    this.loading = false;
                    this.closeDialog();
                    await this.$parent.agentTerminals();
                } catch (error) {
                    this.loading = false;
                    let message = '';
                    if (error.response.status === 422) {
                        message = error.response.data.message[0];
                    } else if (error.status === 500) {
                        message = 'Something went wrong, please try again';
                    } else {
                        message = error.response.data.message;
                    }
                    this.$toast.error(message);
                }
            },

            async withdrawFund() {
                this.form.terminal_id = this.item.terminalId;
                this.loading = true;
                try {
                    let response = await this.withdraw_fund(this.form);
                    this.$toast.success(response.message);
                    this.loading = false;
                    this.closeDialog();
                    await this.$parent.agentTerminals();
                } catch (error) {
                    this.loading = false;
                    let message = '';
                    if (error.response.status === 422) {
                        message = error.response.data.message['amount'][0];
                    } else if (error.status === 500) {
                        message = 'Something went wrong, please try again';
                    } else {
                        message = error.response.data.message;
                    }
                    this.$toast.error(message);
                }
            },

            closeDialog() {
                this.dialog = false;
            }
        },
    }
</script>

<style scoped>

</style>