<template>
  <div>
    <v-dialog
      max-width="450"
      v-model="dialog"
    >
      <v-card
        class="white pa-5"
      >
        <v-toolbar flat class="d-flex justify-center primary--text">Assign Teller</v-toolbar>
        <v-divider class="primary"></v-divider>
        <v-form lazy-validation ref="teller" class="mt-4">
          <label for="" class="primary--text"><small>Name</small></label>
          <v-text-field
            dense
            v-model="form.name"
            outlined
          ></v-text-field>
          <label for="" class="primary--text"><small>Location</small></label>
          <v-text-field
            dense
            v-model="form.location"
            outlined
          ></v-text-field>
          <label for="" class="primary--text"><small>TerminalID</small></label>
          <v-text-field
            dense
            outlined
            readonly
            v-model="form.terminalID"
          ></v-text-field>
          <v-card-actions class="justify-end">
            <v-btn
              :ripple="false"
              color="grey lighten-1"
              depressed
              @click="closeDialog()"
            >Close</v-btn>
            <v-btn
              :ripple="false"
              color="primary"
              depressed
              :loading="loading"
              @click="$refs.teller.validate() ? assignTeller() : null"
            >Submit</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      dialog: false,
      form: {},
      loading: false,
    }
  },

  methods: {
    ...mapActions('pos', [
      'assignteller'
    ]),

    openDialog(item) {
      this.form.user_id     = item.user_id
      this.form.terminalID  = item.terminalId
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
    },

    async assignTeller() {
      this.loading = true
      try {
        let response = await this.assignteller(this.form)
        this.loading = false
        this.$toast.success(response.message)
      } catch (error) {
        this.loading = false
        this.$toast.error(error.response.data.message)
      }
    }
  },
}
</script>